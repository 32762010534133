    .social-icon a {
    margin-left: 15px; /* Adjust the margin as needed for spacing between icons */
  }
  
  .social-icon img {
    width: 30px; /* Adjust the size of the icons */
    height: 30px;
  }
  
  p {
    margin-top: 10px; /* Adjust the margin as needed for spacing between the social icons and the text */
    font-size: 14px; /* Adjust the font size as needed */
    color: #888; /* Adjust the text color as needed */
  }
  .social-icon {
    display: flex;
    align-items: left;
  }
  
  .text-md-end {
    text-align: left; /* Adjust this based on your specific requirements */
  }
  .text-left {
    text-align: left;
  }
  .contact-container {
    display: flex;
    align-items: center;
  }
  
  .contact-container h5 {
    margin-right: 10px; /* Adjust the margin as needed */
  }
  .text-left {
    text-align: center;
  }
  
  .text-left h6 {
    margin: 0; /* Optional - Remove default margin if needed */
  }
  
  .text-left h6 a {
    color: white;
    text-decoration: none;
  }
  