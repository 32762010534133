
.about-container {
  padding-top: 15px;
  text-align: center;
  width : 70%;
  border-radius: 10px; /* Adjust the border radius as desired */
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .about-container {
    padding-top: 200px; /* Adjust the top padding as desired for mobile */
  }
}
.about-container2{
  padding-top: 15px;
  text-align: center;
  width : 60%;
  border-radius: 10px; /* Adjust the border radius as desired */
  display: flex;
  justify-content: center;
  align-items: center;
}
.section1 {

  display: flex;
  flex-direction: row;
  align-items: center;
}
.values-image {
  grid-row: 1; /* Place the image in row 1 */
}
.mission-items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
@media screen and (max-width: 767px) {
  .about-container,.about-container2 {
    width: 100%;
  }


  .mission-item {
   
    font-size: 10px; /* Adjust the font size to your preference */
    width: 100%; /* Adjust the width to cover 3/4th of the page horizontally */
    background-color: white; /* Set the background color to purple */
    padding: 10px; /* Add some padding around the content */
  }
}






.animate-zoom-in {
  animation: zoom-in 1s ease-in-out;
}

@keyframes zoom-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

/* styles.css */
.custom-heading {
  padding-right: 5px;
}



  
  .section {
    background-color: white;
    border-radius: 10px;
    padding: 20px 0;
 
  }
  .tagline {
    padding: 60px 30px 20px;
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  .vision {
    font-size: 30px;
    text-align: center;
    font-family: 'Arial', sans-serif;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
 
  
  .mission2-items {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    grid-column: 2; /* Place the image in column 2 */
  grid-row: 1; /* Place the image in row 1 */

  }
  
  .mission2-item {
 
    justify-content: flex-end;
    margin-bottom: 10px;
  }
  
  .title {
    text-align: center;
    font-family: "Arial", sans-serif;

    color: black;
  }
  .vision-item {
    font-size: 10px; /* Adjust the font size to your preference */
    width: 80%; /* Adjust the width to cover 3/4th of the page horizontally */
    background-color: black; /* Set the background color to purple */
    padding: 10px; /* Add some padding around the content */
    /* Add any other desired styling */
  }
  
  .mission-item {
    font-size: 10px; /* Adjust the font size to your preference */
    width: 90%; /* Adjust the width to cover 3/4th of the page horizontally */
    background-color: white; /* Set the background color to purple */
    padding: 10px; /* Add some padding around the content */
  }
  .about-item {
    font-size: 10px; /* Adjust the font size to your preference */
    width: 100%; /* Adjust the width to cover 3/4th of the page horizontally */
    background-color: white; /* Set the background color to purple */
    padding: 10px; /* Add some padding around the content */
    /* Add any other desired styling */
  }
  .about-item h3,
  .about-item h4 {
    color: #1a0537;
    font-size: 17px; /* Adjust the font size as desired */
  }
  
   
  .vision-item h3,
  .vision-item h4 {
    color: #1a0537;
    font-size: 17px; /* Adjust the font size as desired */
  }
  .mission-item h3,
  .mission-item h4 {
    color: #1a0537;
    font-size: 19px; /* Adjust the font size as desired */
    text-align: left;
  }

.mission2-item h3,
.mission2-item h4 {
  color: #1a0537;
  font-size: 19px; /* Adjust the font size as desired */
  text-align: justify;
}
  
  .values {
    /* CSS styles for the Values section */
    flex: 3;
    background-color: white;
    color: white;
    padding: 20px;
  }
  .values-container {
    width: 100%;
  }
  
  .About {
    /* Styles for large screens */
    color: #1a0537;
    font-size: 19px; /* Adjust the font size as desired */
  }
  
  @media (max-width: 767px) {
    /* Styles for mobile screens */
    .About {
      text-align: left;
      color: #1a0537;
      font-size: 19px; /* Adjust the font size as desired */
    }
  }
  
  
  @keyframes slide-up {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animate-slide-up {
    animation: slide-up 1s ease-in-out;
  }
  
@media screen and (max-width: 767px) {
  .about-container,
  .about-container2 {
    width: 100%;
  }

  .tagline h1.tagline {
    font-size: 24px;
    padding: 40px 20px 10px;
  }

  .about-container2 h1.vision,
  .about-container2 .vision,
  .about-container2 h4.custom-heading {
    font-size: 16px;
  }

  .about-container2 div.values img {
    width: 100px;
  }

  .about-container2 div.mission2-items div.mission2-item {
    margin-bottom: 5px;
  }

  .about-container2 div.values h1.vision {
    font-size: 24px;
    margin-bottom: 0px;
  }

  .about-container2 div.values img {
    width: 100px;
  }

  .about-container2 div.mission2-items div.mission2-item {
    margin-bottom: 5px;
  }

  .about-container2 div.values-container h1.vision {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .about-container2 div.values-image img {
    width: 100px;
  }

  .about-container2 div.mission2-items div.mission2-item {
    margin-bottom: 5px;
  }
}
  