.app {
  text-align: center;
  background-color: #1a0537;
  color: #ffffff;
}


@media (max-width: 767px) {
  .container {
    padding: 100px;
    /* Add additional styling properties for mobile view */
  }
  .service-cards {
    padding: 10px; /* Add padding for mobile view */
    /* Add additional styling properties for mobile view */
  }
}

@media (max-width: 574px) {
  .popup {
    max-width: 100%;
    /* Add other styles specific to mobile */
  }
}


.service-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  justify-items: center;
}

.service-card {
  width: 200px;
  padding: 20px;
  margin-bottom: 20px;
  background: linear-gradient(to bottom right, #8629a6, #3c135c);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
}


.service-card__image {
  width: 100%;
  max-height: 150px; /* Adjust the value as desired */
  object-fit: contain;
  border-radius: 5px;
  margin-bottom: 10px;
}

.service-card__title {
  margin-bottom: 8px;
  color: #ffffff;
  font-size: 30px; /* Adjust the value as desired */
  font-weight: bold;
}
.container2 {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 200px;
  padding-bottom: 200px;
}

.service-cards {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.service-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s;
  text-align: center;
}
.service-card:hover {
  transform: scale(1.3); /* Adjust the scale factor as desired */
}
.service-card__image {
  width: 100px;
  height: 100px;
}

.service-card__title {
  font-size: 18px;
  margin-top: 10px;
}

@media (max-width: 767px) {
  
  .service-cards {
    grid-template-columns: 1fr;
    /* Add additional styling properties for mobile view */
  }
}


.container2 {
  position: relative;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background-image: white;
  width: 1200px;
  height: 600px ;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .popup {
    background-color: white;
    width: 100%;
    max-height: 90vh;
    border-radius: 10px;
    display: flex;

    padding: 20px;
    overflow: auto;
    width:1000px;
  }

  .popup__details {
    font-size: 10px;
    color: black;
  }
}

  .service-cards {
    grid-template-columns: 1fr;
    /* Add additional styling properties for mobile view */
  }

.popup__details {
  font-size: 17px;
  color: black;
}

.close-button {
  background-color: transparent;
  color: black;
  border: none;
  font-size: 25px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Adjust the opacity here */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.close-button:hover {
  color: red;
}
@media (max-width: 768px) {
  .item img {
    width: 100%;
  }
}
