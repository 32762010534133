

@media (max-width: 574px) {
  .popup1 {
    max-width: 100%;
    /* Add other styles specific to mobile */
  }
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup1 {
  background-color: white;
  width: 1200px;
  height: 600px ;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .popup1 {
    background-color: white;
    width: 100%;
    max-height: 90vh;
    border-radius: 10px;
    display: flex;

    padding: 20px;
    overflow: auto;
    width:1000px;
  }

 
}



.close-button {
  background-color: transparent;
  color: black;
  border: none;
  font-size: 25px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Adjust the opacity here */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.close-button:hover {
  color: red;
}
