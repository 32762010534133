.newsletter-container {
    text-align: center;
    padding: 20px;
  }
  
  .newsletter-bx {
    background: #FFFFFF;
    border-radius: 55px;
    color: #121212;
    padding: 85px 125px;
    margin-bottom: 80px;
    margin-top: -122px;
  }
  
  .newsletter-bx h5 {
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 1.2em;
  }
  
  .new-email-bx {
    background: #fff;
    padding: 5px;
    border-radius: 20px;
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
  }
  
  .new-email-bx::before {
    content: "";
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    border-radius: 20px;
    position: absolute;
    z-index: -1;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
  }
  
  .new-email-bx::after {
    content: "";
    background: #fff;
    border-radius: 20px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  .new-email-bx input[type="email"] {
    width: 100%;
    color: #121212;
    font-weight: 500;
    background: transparent;
    border: 0;
    padding: 0 15px;
  }
  
  .submit-col {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  
  .submit-btn button {
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    padding: 20px 65px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.5px;
    border-radius: 18px;
  }
  
  @media (max-width: 767px) {
    .newsletter-bx {
      padding: 65px 45px;
    }
  
    .newsletter-bx h5 {
      font-size: 16px;
    }
  
    .newsletter-row {
      flex-direction: column;
    }
  
    .submit-col {
      margin-top: 10px;
    }
  }
.newsletter-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.newsletter-container {
    margin-top: 30px;
  }
  
  .newsletter-bx {
    background: #ffffff;
    border-radius: 55px;
    color: #121212;
    padding: 60px;
  }
  
  .newsletter-heading {
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 1.2em;
    margin-bottom: 30px;
  }
  
  .new-email-bx {
    background: #fff;
    padding: 10px;
    border-radius: 20px;
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
  }
  
  .new-email-bx::before {
    content: "";
    background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
    border-radius: 20px;
    position: absolute;
    z-index: -1;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
  }
  
  .new-email-bx::after {
    content: "";
    background: #fff;
    border-radius: 20px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  .new-email-bx input {
    width: 100%;
    color: #121212;
    font-weight: 500;
    background: transparent;
    border: 0;
    padding: 0 15px;
  }
  
  .submit-col {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  
  .submit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .submit-btn button {
    background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
    padding: 15px 40px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.5px;
    border-radius: 18px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .submit-btn button:hover {
    background-color: #000000;
  }

  .social-links {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  